import { defineComponent } from 'vue';
import QuartlyProductions from '@/views/QuartlyProduction/QuartlyProductions.vue';
export default defineComponent({
    name: 'QuartlyProductionsLayout',
    components: { QuartlyProductions },
    data() {
        return {
            loading: false,
            tab: 'Quartly Productions'
        };
    }
});
