import { render } from "./QuartlyProductions.vue?vue&type=template&id=78891326&scoped=true"
import script from "./QuartlyProductions.vue?vue&type=script&lang=ts"
export * from "./QuartlyProductions.vue?vue&type=script&lang=ts"

import "./QuartlyProductions.vue?vue&type=style&index=0&id=78891326&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-78891326"

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QBtn,QTable,QInput,QIcon,QTr,QTh,QTd,QSpace});
